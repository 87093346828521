import axios, { Method } from "axios";

const baseURL = process.env.REACT_APP_API_URL;
/* const baseURL = "http://localhost:3333/"; */
const api = axios.create({ baseURL });

type HttpResquestReturn<T> = {
  isOk: false;
  error: string;
} | {
  isOk: true;
  data: T;
  message?: string;
}

export const httpRequest = {
  get: async <T = any>(url: string) => {
    return await _makeRequest<T>("GET", url);
  },

  delete: async <T = any>(url: string) => {
    return await _makeRequest<T>("DELETE", url);
  },

  post: async <T = any>(url: string, data?: any) => {
    return await _makeRequest<T>("POST", url, data && data);
  },

  put: async <T = any>(url: string, data?: any) => {
    return await _makeRequest<T>("PUT", url, data);
  },
}


async function _makeRequest<T = any>(
  method: Method,
  url: string,
  data?: any
): Promise<HttpResquestReturn<T>> {
  const authToken = localStorage.getItem("inb3.bearerToken");
  try {
    if (process.env.REACT_APP_DEBUG_MODE === "dev") {
      // console.log({
      //   name: 'REQUEST_BEGIN',
      //   date: new Date().toISOString(),
      //   httpMethod: method,
      //   url: `${baseURL}${url}`,
      //   authToken: `Bearer ${authToken}`,
      //   /* authToken: `${authToken}`, */
      //   body: data,
      // });
    }

    const headers = {
      ...(authToken && { Authorization: `Bearer ${authToken}` })

    }

    const httpMethod = method.toLowerCase();

    let response;

    method === 'POST' || method === 'PUT'
      ? response = await api[httpMethod as 'post'](url, data, { headers })
      : response = await api[httpMethod as 'get'](url, { headers });

    return {
      isOk: true,
      data: response.data,
    }
  } catch (err: any) {
    if (process.env.REACT_APP_DEBUG_MODE === "dev") {
      console.error({
        name: 'REQUEST_ERROR',
        date: new Date().toISOString(),
        httpMethod: method,
        url: `${baseURL}${url}`,
        error: err,
      });
    }

    const error = _makeErrorMessage(err);

    return { isOk: false, error }
  }
}

function _makeErrorMessage(err: any) {
  let finalError = "";

  if (err.message) finalError = err.message;

  if (err.response && err.response.data) {
    const e = err.response.data;

    if (e.response && e.response.msg) finalError = e.response.msg;
    else if (e.response) finalError = e.response;
    else if (e.message) finalError = e.message;
  }

  if (!finalError) finalError = "Houve um erro. Por favor, entre em contato!";

  return finalError;
}