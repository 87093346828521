import { forwardRef, ForwardRefRenderFunction, ReactElement } from "react";
import { Input, InputProps, FormControl, FormErrorMessage, InputGroup} from "@chakra-ui/react";

interface Props extends InputProps {
  name: string;
  label?: string;
  errorMessage?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  helperText?: string;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, Props> = ({ name, label, errorMessage, leftIcon, rightIcon, helperText, ...rest }, ref) => {
  return (
    <FormControl isInvalid={Boolean(errorMessage)} mb="4">
      <InputGroup>
        <Input
          id={`name_${name}-label_${label}`}
          name={name}
          _focus={{bgColor: "gray.50", borderColor:"gray.900"}}
          bgColor="gray.50"
          _hover={{ bgColor: "gray.50" }}
          borderRadius="md"
          variant="filled"
          size="md"
          placeholder={label}
          ref={ref}
          {...rest}
        />
      </InputGroup>

      {Boolean(errorMessage) && (
        <FormErrorMessage mt="0" ml="2">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export const InputText = forwardRef(BaseInput);