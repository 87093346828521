import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Image
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";


export function Landing() {
    const navigate = useNavigate();
  return (
    <Container maxW={'5xl'} bgColor="#F5F5F5" >
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}>
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}>
            Alcance  <Text as={'span'} color={'green.900'}>melhores resultados</Text>  com auxílio da inteligência artificial IA
        </Heading>
        <Text color={'gray.500'} maxW={'3xl'}>
        iNB3 é a ferramenta matemática que auxilia o investidor para a <Text as={'span'} color={'green.900'}>melhor tomada de decisão</Text>.
        </Text>
        <Text color={'gray.500'} maxW={'3xl'}>
        Fuja do achismo! Use a matemática a seu favor e <Text as={'span'} color={'green.900'}>maximize seus acertos</Text>.
        </Text>
        <Text color={'gray.500'} maxW={'3xl'}>
        O instrumento que une<Text as={'span'} color={'green.900'}> análise técnica e fundamentalista</Text>  de uma forma simples e precisa.
        </Text>
        <Stack spacing={6} direction={'row'}>
          <Button
            rounded={'full'}
            px={6}
            colorScheme={'green'}
            bg={'green.900'}
            onClick={() => navigate("/login")}>
            Conhecer a iNB3
          </Button>
        </Stack>
        <Flex w={'full'} alignItems={'center'} justifyContent={'center'} bgColor="#F5F5F5" >
        <Image src="/images/pngs/inb3_logo.png" alt="inb3 logo" w={130} alignSelf='center'/>
        </Flex>
        <Text color={'gray.500'} maxW={'xl'} fontSize={'sm'}>
        Todas as informações apresentadas tem caráter informativo e são provenientes de fontes públicas como B3, CVM, TESOURO NACIONAL, etc. e de dados calculados a partir das informações coletadas. A iNB3 não tem o objetivo de fazer sugestão de compra ou venda de ativos, sendo assim, não se responsabiliza pelas decisões e caminhos tomados a partir da análise das informações aqui apresentadas.
        </Text>
      </Stack ><Text color={'gray.500'}  fontSize={'3xs'} align={'center'}>
      Versão: 1.2 
        </Text>
    </Container>
  )
}