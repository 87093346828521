import { Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import WalletCurrentScore from "../../../components/WalletCurrentStock";
import { userStore } from "../../../store/user";

export function WalletScoreCurrentScreen() {
  const {user} = userStore();
  const navigate = useNavigate();
    
    if(!user.paid){
        return (
            <Flex flexDir='column'>
            <Text>Área exclusiva para usuários premium.</Text>
            <Text alignSelf={'center'} fontSize='lg' color={'green.50'} fontWeight='bold' _hover={{
              cursor: "pointer",
            }} onClick={() => {
        navigate(`/app/conta`)
        }}>Assine já!</Text>
            </Flex>
        )
      }
    return (
        <>
        <WalletCurrentScore percentage={42} title="Minha Carteira"/>
        </>
    )
 }