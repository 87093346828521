import { BrowserRouter } from "react-router-dom";
import { RoutesProvider } from "./routes";
import { ThemeProvider } from "./theme";

export const App = () => (
  <BrowserRouter>
    <ThemeProvider>
      <RoutesProvider />
    </ThemeProvider>
  </BrowserRouter>
);
