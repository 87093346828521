import create from "zustand";
import { User } from "../types/user";

export interface UserState {
  user: User;
  setUser: (user: User) => void;
  removeUser: () => void;
}

export const userStore = create<UserState>((set) => ({
  user: {} as User,

  setUser: user => set(state => ({
    ...state,
    user
  })),

  removeUser: () => set(state => ({
    ...state,
    user: {} as User
  })),
}));