import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import ChartStatistics from "../ChartStatistics";
import {
    FiHome,
  } from 'react-icons/fi';
  import { TbNumber2,
  } from 'react-icons/tb';
import { walletStore } from "../../store/wallet";
import { mainDataStore } from "../../store/mainData";
import { Loading } from "../Loading";
import { useEffect, useState } from "react";
import { userStore } from "../../store/user";
import { httpRequest } from "../../services/api";
import { WalletItem } from "../../types/walletItem";

const WalletCurrentScore = ({ title, percentage, chart }: any) => {
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");


  const {wallet, setWallet} = walletStore();
  const {mainData} = mainDataStore();

  const filteredData = mainData.filter((item) => wallet.includes(item.Ativo));
  const stockSymbols = filteredData.map(item => item.Ativo.toUpperCase());
  const sumValues = filteredData.reduce((accumulator, item) => {
    accumulator.ca +=  Number(item.ca) < 0 || Number(item.ca) === Infinity || isNaN(item.ca!) || item.P_VP < 0 ||  item.P_L < 0 ? 0 : parseFloat(item.ca.toString()); // Convert "ca" to a number and add to the accumulator
    accumulator.score += item.score!;
    return accumulator;
  }, { ca: 0, score: 0 });
  
  const averageCa = (sumValues.ca / filteredData.length);
  const averageScore = sumValues.score / filteredData.length;

  const [isLoading, setLoading] = useState(false);
  const {user} = userStore()
  const {setMainData} = mainDataStore()


  useEffect(() => {
    setLoading(true);
    if(mainData.length === 0){

        if(Object.keys(user).length > 0){
          if(!user.paid){
            httpRequest.get('/stocksPaginated?page=1&totalPerPage=1000').then(res => {
              if (res.isOk) {
                const newArray = res.data.items
                setMainData( newArray);
              }
              setLoading(false);
            });
            return;
          }
          httpRequest.get('/stocksPaginatedPremium').then(res => {
            if (res.isOk) {
              const newArray = res.data.items
              setMainData(newArray.sort((a: any, b: any) => {
                // First, sort by score in descending order
                if (b.score !== a.score) {
                  return b.score - a.score;
                }
              
                // If scores are the same, use ca as a tiebreaker
                return b.ca - a.ca;
              }));
            }
      
          });
        }
    }
   
    setLoading(false);
  }, [user]);

  return (
    <>
    {
      isLoading ? <Loading/> :  <Card p='16px'>
      <CardBody>
        <Flex direction='column' w='100%'>
          <Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
            <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
              {title}
            </Text>
            <Text fontSize='md' fontWeight='medium' color='gray.400'>
              <Text
              fontSize={99}
                as='span'
                color={averageScore !== 0 ? "green" : "red.400"}
                fontWeight='bold'>
                { `${averageScore ? averageScore.toFixed(2) : 0}`}
              </Text>{" "}
              : score médio da carteira
            </Text>
          </Flex>
          <SimpleGrid gap={{ sm: "48px" }} columns={3} >
            <ChartStatistics
              title={"Tickers"}
              amount={`${stockSymbols.join(', ')}`}
              icon={<FiHome height={"15px"} width={"15px"} color={iconBoxInside} />}
            />
            
          </SimpleGrid>
          <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
          <ChartStatistics
              title={"Score médio"}
              amount={averageScore ? averageScore.toFixed(2) : 0}
              icon={<FiHome height={"15px"} width={"15px"} color={iconBoxInside} />}
            />
            <ChartStatistics
                title={"CA médio"}
                amount={ averageCa && !isNaN(averageCa) ? `${averageCa.toFixed(2)}%` : 0}
              icon={<TbNumber2 height={"15px"} width={"15px"} color={iconBoxInside} />}
            />
          </SimpleGrid>
        </Flex>
      </CardBody>
    </Card>
    }
    </>
   
  );
};

export default WalletCurrentScore;
