import * as yup from "yup";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Flex, Text, Image} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Form } from "../../../../components/Form";
import { InputText } from "../../../../components/InputText";
import { Button } from "../../../../components/Button";
import { httpRequest } from "../../../../services/api";
import { useToast } from "../../../../hooks/useToast";
import { AiOutlineArrowRight } from "react-icons/ai";
import { userStore } from "../../../../store/user";

interface Props {
    setCurrentStep: Dispatch<SetStateAction<number>>;
}

export function LoginFormStep1({ setCurrentStep }: Props) {
    const {setUser} = userStore();
    const [emailValue, setEmailValue] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

    const [isLoading, setLoading] = useState(false);
    const toast = useToast();

    async function handleLogin(event: FormEvent) {
        event.preventDefault();

        setPasswordErrorMessage("");
        setEmailErrorMessage("");

        if (!emailValue) {
            return setEmailErrorMessage("E-mail é obrigatório");
        }

        if (!password) {
            return setPasswordErrorMessage("Senha é obrigatória");
        }

        yup.string().email().required().validate(emailValue)
            .then(emailValidated => {
                setEmailValue(emailValidated);
            })
            .catch(() => {
                return setEmailErrorMessage("E-mail inválido")
            })


        setLoading(true);

        const result = await httpRequest.post("/auth/login", { email: emailValue, password });

        if (!result.isOk) {
            setLoading(false);
            if (result.error) toast.error(result.error);
            return;
        }
        
        const token = result.data.access_token
        localStorage.setItem('inb3.bearerToken', token);
        httpRequest.get("/auth/me").then(res => {
                  if (!res.isOk) {
                      localStorage.removeItem("inb3.bearerToken");
                      setLoading(false);
                      return;
                    } 
         
                    setUser(res.data);
           
                  });
        navigate("/app");
        setLoading(false);
            
   
    }


    return (
        <>
            <Form onSubmit={handleLogin} w="100%" justify="center" mt="1.5">
            <Flex alignSelf="center"><Image src="/images/pngs/inb3_logo.png" alt="inb3 logo" w={130} /></Flex>

                <Text color="blue.900" fontSize="2xl" mb="8">Log In</Text>

                <InputText
                    name="emailOrPhone"
                    label="Endereço de e-mail"
                    onChange={e => {
                        setEmailValue(e.target.value);
                        setEmailErrorMessage("");
                    }}
                    value={emailValue}
                    errorMessage={emailErrorMessage}
                />

                <InputText
                    name="password"
                    label="Senha"
                    type="password"
                    onChange={e => {
                        setPassword(e.target.value);
                        setPasswordErrorMessage("");

                    }}
                    value={password}
                    errorMessage={passwordErrorMessage}
                />

   {             <Flex alignSelf="flex-end" direction="column"
                    mb="2"
                    _hover={{
                        cursor: "pointer"
                    }}
                    onClick={() => navigate("/recuperar-senha")}
                >
                    <Text color="green.50" textDecoration="underline" fontSize="14">Esqueceu sua senha?</Text>
                </Flex>}

                <Button text="Log In" type="submit" my="1" loading={isLoading} />

                <Flex alignSelf="flex-start" mt="12" direction="column"
                    _hover={{
                        cursor: "pointer"
                    }}
                    onClick={() => navigate("/cadastro")}
                >
                    <Text color="blue.900" fontWeight="semibold">Não possui uma conta?</Text>
                    <Flex direction="row" align="center">
                        <Text color="blue.50" mr="2" mt="0.5" fontWeight="semibold">R E G I S T R A R</Text>
                        <AiOutlineArrowRight color="#314BCE" size={20} />

                    </Flex>
                </Flex>

            </Form>
        </>

    );
}
