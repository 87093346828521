import {
    Flex,
    Td,
    Text,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import { useNavigate } from "react-router-dom";
import { walletStore } from "../../store/wallet";
import { Button } from "../Button";
import { userStore } from "../../store/user";

  function DashboardTableRow(props: any) {
    const { name, members, budget, score, ca, pl, pvp, hasRemove } = props;
    const textColor = useColorModeValue("gray.700", "white");
  const navigate = useNavigate();
  const {addTickToWallet, removeTickFromWallet, wallet, walletIsLoading} = walletStore();
  const {user} = userStore()

    const getColorScore = (score: number) => {
      if(score > 7) return 'green'
      if(score <= 7 && score >= 4) return 'orange'
      if(score < 4) return 'red'
    }

    const getColorCA = (ca: number) => {
      if(ca > 3) return 'green'
      if(ca <= 3 && ca >= 1) return 'orange'
      if(ca < 1) return 'red'
      return 'red'
    }
    return (
      <Tr  cursor={"pointer"}>
        <Td  minWidth={{ sm: "250px" }} pl="0px" onClick={() => {
        navigate(`/app/acao-score-atual/${members}`)
        }}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Td>
  
        <Td onClick={() => {
        navigate(`/app/acao-score-atual/${members}`)
        }}>
        <Flex align="center" py=".9rem" minWidth="100%" flexWrap="nowrap">
   
            <Text
              fontSize="md"
              color={"green"}
              fontWeight="bold"
              minWidth="100%"
            >
              {members}
            </Text>
          </Flex>

        </Td>
        <Td onClick={() => {
        navigate(`/app/acao-score-atual/${members}`)
        }}>
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
           R$ {budget?.toLocaleString('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false
})}
          </Text>
        </Td>
        <Td onClick={() => {
        navigate(`/app/acao-score-atual/${members}`)
        }}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={getColorScore(score)}
              fontWeight="bold"
              pb=".2rem"
            >{`${score  === 'locked' ? '🔒' : score}`}</Text>
          </Flex>
        </Td>

        <Td onClick={() => {
        navigate(`/app/acao-score-atual/${members}`)
        }}>
          <Flex direction="column">
            <Text
              fontSize="md"
              color={pvp < 0 ||  pl < 0 ? 'red' : getColorCA(Number(ca))}
              fontWeight="bold"
              pb=".2rem"
              >{`${ca === 'locked' ? '🔒' : Number(ca) < 0 || Number(ca) === Infinity || isNaN(ca!) || pvp < 0 ||  pl < 0 ?  'N/A' : ca?.toFixed(2) + '%'}`}</Text>
          </Flex>
        </Td>
 
        {
          hasRemove &&  <Td>
          <Flex direction="column">
         {user.paid && <>{ wallet.includes(members) ?<Button isDisabled={walletIsLoading} text="Remover da carteira"   w='100%' color="red" onClick={() => {
                    removeTickFromWallet(members, name)
}}/>  : <Button isDisabled={walletIsLoading} text="Adicionar a carteira" w='100%' color="green" onClick={() => {
  addTickToWallet(members, name)
}}/>}</> }
          </Flex>
        </Td>
        }
      </Tr>
    );
  }
  
  export default DashboardTableRow;
  