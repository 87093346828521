import { useCallback } from "react";
import { useToast as useChakraToast } from "@chakra-ui/react";

export function useToast() {
  const toast = useChakraToast();

  const success = useCallback((text: string) => {
    let description = "";

    if (typeof text === "object") {
      description = Object.values(text)[0] as string;
    } else {
      description = text;
    }

    return toast({
      position: "top-right",
      description,
      status: "success",
      isClosable: true,
    });
  }, [toast]);

  const error = useCallback((text: string) => {
    let description = "";

    if (typeof text === "object") {
      description = Object.values(text)[0] as string;
    } else {
      description = text;
    }

    return toast({
      position: "top-right",
      description,
      status: "error",
      isClosable: true,
    });
  }, [toast]);

  return {
    success,
    error,
  }
}