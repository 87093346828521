import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";
import { Loading } from "../Loading";

type Props = ButtonProps & {
    text?: string;
    loading?: boolean;
    color?: string;
}

export function Button({ children, text, loading, color, ...rest }: Props) {
    return (
        <ChakraButton
            borderRadius="md"
            backgroundColor={color}
            variant="solid"
            _focus={{ outline: "none" }}
            _hover={{
                backgroundColor: {color},
                ...rest._hover,
            }}
            {...rest}
        >
            {loading ? <Loading size="sm"/> :
                <>
                    {text ? text : children}
                </>}

        </ChakraButton>
    );
}