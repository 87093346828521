import { Flex } from "@chakra-ui/react";
import { LoginForm } from "./partials/SignupForm";

export function SignUp() {
  return (
      <Flex justify="center" 
      align="center" 
      p="8" 
      bgColor="white" 
      w={476} 
      m="auto" 
      mx={"5"} 
      borderRadius="12" 
      direction="column"> 

      <LoginForm/>
      </Flex>
  );

  }