import { Route, Routes } from "react-router-dom";
import * as Public from "../pages/public";
import * as App from "../pages/app";
import { LayoutWrapper } from "./layouts";
import { ACLWrapper } from "./acl";
import { IRoute } from "./types";

export const RoutesProvider = () => (
  <Routes>
    {routesList.map(({ acl, element: Element, layout, path }, index) => (
      <Route key={`${index}-${path}`} path={path} element={(
        <ACLWrapper type={acl}>
          <LayoutWrapper type={layout}>
            <Element />
          </LayoutWrapper>
        </ACLWrapper>
      )} />
    ))}
  </Routes>
);

const routesList: IRoute[] = [
  { acl: "app", layout: "app", path: "/app", element: App.RankingStockScoreScreen },
  { acl: "app", layout: "app", path: "/app/ranking-acao-score", element: App.RankingStockScoreScreen },
  { acl: "app", layout: "app", path: "/app/ranking-carteira-score", element: App.RankingWalletScoreScreen },
  { acl: "app", layout: "app", path: "/app/conta", element: App.Conta },
  { acl: "app", layout: "app", path: "/app/compara-atual-acao-score", element: App.ComparingCurrentStockScoreScreen },
  { acl: "app", layout: "app", path: "/app/acao-score-historico", element: App.StockScoreHistoryScreen },
  { acl: "app", layout: "app", path: "/app/acao-score-atual/:ticker", element: App.StockScoreCurrentScreen },
  { acl: "app", layout: "app", path: "/app/entenda", element: App.Entenda },
  { acl: "app", layout: "app", path: "/app/carteira-score-atual", element: App.WalletScoreCurrentScreen },
  { acl: "app", layout: "app", path: "/app/usuario-editar-carteira", element: App.UserWalletEditsScreen },

  { acl: "public", layout: "none", path: "/", element: Public.Landing },
  { acl: "public", layout: "public", path: "/login", element: Public.Login },
  { acl: "public", layout: "public", path: "/cadastro", element: Public.SignUp },
  { acl: "public", layout: "public", path: "/recuperar-senha", element: Public.ForgotPassword },
/*   { acl: "public", layout: "public", path: "/login/admin", element: Public.AdminLogin },
  { acl: "public", layout: "public", path: "/login/admin/recuperar-senha", element: Public.AdminResetPassword },
  { acl: "public", layout: "public", path: "/login/admin/confirmar-senha", element: Public.AdminResetPasswordConfirmation },

  { acl: "public", layout: "public", path: "/login/recuperar-senha/:email", element: Public.ResetPassword },
  { acl: "public", layout: "public", path: "/login/confirmar-senha", element: Public.ResetPasswordConfirmation },
  { acl: "public", layout: "public", path: "/", element: Public.Login },
  { acl: "public", layout: "public", path: "/:notFound", element: Public.Login }, */
];