export const arrayToBeFiltered = [
    "CORR4",
    "CORR3",
    "TRPN3",
    "EQPA7",
    "HBTS5",
    "NEMO5",
    "SMLS3",
    "CPRE3",
    "EEEL3",
    "EEEL4",
    "ELPL3",
    "MPLU3",
    "QGEP3",
    "TIET3",
    "TIET4",
    "BRGE12",
    "CALI4",
    "FIGE3",
    "FNCN3",
    "NEMO3",
    "NEMO6",
    "OMGE3",
    "TIET11",
    "BPHA3",
    "BRQB3",
    "BSEV3",
    "CATA3",
    "COCE6",
    "GETT3",
    "GETT4",
    "IGBR3",
    "IGTA3",
    "MMAQ4",
    "NAFG4",
    "ODER4",
    "PPAR3",
    "STKF3",
    "STTR3",
    "TKNO3",
    "ANDG4B",
    "BFRE11",
    "BFRE12",
    "BPAR3",
    "BPAT33",
    "ENMA3B",
    "ENMA6B",
    "FIBR3",
    "FIGE4",
    "INNT3",
    "LCAM3",
    "MSRO3",
    "ALSC3",
    "BEEF11",
    "BOBR3",
    "CTCA3",
    "DTCY4",
    "EMAE3",
    "HOOT3",
    "LTEL3B",
    "LUXM3",
    "NAFG3",
    "NRTQ3",
    "PINE3",
    "PTCA11",
    "PTCA3",
    "ANDG3B",
    "BTTL4",
    "GETT11",
    "GRAO3",
    "LAME3",
    "LAME4",
    "LHER3",
    "LHER4",
    "MGEL3",
    "MGEL4",
    "PARD3",
    "ADHM3",
    "BBML3",
    "BRIN3",
    "CATA4",
    "CEPE3",
    "FBMC3",
    "FBMC4",
    "FLEX3",
    "IGSN3",
    "MMAQ3",
    "POWE3",
    "CEPE5",
    "CEPE6",
    "CNSY3",
    "ELEK4",
    "ELEK3",
    "MAGG3",
    "OGXP3",
    "TESA3",
    "TOYB3",
    "TOYB4",
    "APTI3",
    "APTI4",
    "BIDI11",
    "BIDI3",
    "BIDI4",
    "BLUT3",
    "BLUT4",
    "CCXC3",
    "CMSA3",
    "CMSA4",
    "CREM3",
    "CTSA8",
    "DMMO11",
    "DMMO3",
    "ECPR3",
    "ECPR4",
    "FTRT3B",
    "GBIO33",
    "GNDI3",
    "GUAR4",
    "IDVL3",
    "IDVL4",
    "MMXM3",
    "MODL11",
    "MODL4",
    "MOSI3",
    "MODL3",
    "MTIG3",
    "MTIG4",
    "PNVL4",
    "QVQP3B",
    "SNSY6",
    "SPRI6",
    "SPRI5",
    "SPRT3B",
    "SULA3",
    "SULA11",
    "SULA4",
    "TCNO3",
    "TCNO4",
    "VSPT3",
    "VSPT4",
    "BRBI3",
    "BRBI4",
    "QUSW3",
    "RSUL3",
    "RANI4",
]