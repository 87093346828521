import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../store/user";

export function PublicACL({ children }: PropsWithChildren<{}>) {
  const { user } = userStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.id) {
      navigate("/app");
    }
  }, [navigate, user]);

  return <>{children}</>;
}