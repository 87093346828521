import { PropsWithChildren } from 'react';
import { ChakraProvider, CSSReset, extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

export function ThemeProvider({ children }: PropsWithChildren<{}>) {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      {children}
    </ChakraProvider>
  );
}

const theme = extendTheme(
  {
    fonts: {
      heading: `'Gilroy-Bold', sans-serif`,
      body: `'Gilroy-Bold', sans-serif`,
    },
    colors: {
      blue: {
        "50": "#314BCE",
        "900": "#151940",
      },
      green: {
        "50": "#CAE266",
        "900": "#7ED956",
      },
      gray: {
        "50": "#F5F6FA",
        "900": "#7F8192"
      },
    }
  },
  withDefaultColorScheme({
    colorScheme: "blue",
  }),
  withDefaultColorScheme({
    colorScheme: "orange",
    components: ["Radio", "RadioGroup", "Checkbox"]
  }),
);