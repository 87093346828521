import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import Projects from "../../../components/RankingTable";
import { httpRequest } from "../../../services/api";
import { mainDataStore } from "../../../store/mainData";
import { userStore } from "../../../store/user";
import { walletStore } from "../../../store/wallet";
import { WalletItem } from "../../../types/walletItem";

export function RankingWalletScoreScreen() {
  const {wallet, setWallet} = walletStore();
  const {mainData} = mainDataStore();
  const {user} = userStore();

  const filteredData = mainData.filter((item) => wallet.includes(item.Ativo));
  const [isLoading, setLoading] = useState(true);
  const {setMainData} = mainDataStore();


  useEffect(() => {
    setLoading(true);
    if(mainData.length === 0){
  
        if(Object.keys(user).length > 0){
          if(!user.paid){
            httpRequest.get('/stocksPaginated?page=1&totalPerPage=1000').then(res => {
              if (res.isOk) {
                const newArray = res.data.items
                setMainData( newArray);
              }
              setLoading(false);
            });
            return;
          }
          httpRequest.get('/stocksPaginatedPremium').then(res => {
            if (res.isOk) {
              const newArray = res.data.items
              setMainData(newArray.sort((a: any, b: any) => {
                // First, sort by score in descending order
                if (b.score !== a.score) {
                  return b.score - a.score;
                }
              
                // If scores are the same, use ca as a tiebreaker
                return b.ca - a.ca;
              }));
            }
           
          });
        }
    }
/*     httpRequest.get("/wallet-stocks/all").then(res => {
      if (res.isOk) {
        console.log('entrou no if',res)
        setWallet(res.data.map((item: WalletItem) => item.ticker));
      }
    }); */
    setLoading(false);
  }, []);

  const navigate = useNavigate();
  if(!user.paid){
    return (
        <Flex flexDir='column'>
        <Text>Área exclusiva para usuários premium.</Text>
        <Text fontSize='lg' color={'green.50'} fontWeight='bold' _hover={{
              cursor: "pointer",
            }}alignSelf={'center'} onClick={() => {
        navigate(`/app/conta`)
        }}>Assine já!</Text>
        </Flex>
    )
  }
  

  
    return (
        <>
         {isLoading ? <Loading/> : <Projects
          title={"Ações da sua carteira"}
          amount={filteredData.length}
          captions={["Nome", "Código", "Último (R$)", "Score" , "CA", "Remover"]}
          data={filteredData}
          hasRemove={true}
        />}
        </>
    )
 }