import { PropsWithChildren, useEffect, useState } from "react";
import { Center } from "@chakra-ui/react";
import { Loading } from "../../components/Loading";
import { httpRequest } from "../../services/api";
import { userStore } from "../../store/user";
import { User } from "../../types/user";
import { PublicACL } from "./PublicACL";
import { AppACL } from "./AppACL";
import { ACLTypes } from "../types";
import { walletStore } from "../../store/wallet";
import { WalletItem } from "../../types/walletItem";

interface Props {
  type: ACLTypes;
}

export function ACLWrapper({ type, children }: PropsWithChildren<Props>) {
  const [isLoading, setLoading] = useState(true);
  const { user, setUser, removeUser } = userStore();
  const { setWallet, wallet } = walletStore();

  useEffect(() => {
/*     if (user.id && wallet.length > 0) {
      setLoading(false);
      return;
    } */
    if (localStorage.getItem("inb3.bearerToken")) {
      Promise.all([
        httpRequest.get("/auth/me"),
        httpRequest.get("/wallet-stocks/all")
      ])
        .then(([authMeRes, walletStocksRes]) => {
          if (!authMeRes.isOk) {
            removeUser();
            localStorage.removeItem("inb3.bearerToken");
            setLoading(false);
            return;
          }
          setUser(authMeRes.data);
          if (!walletStocksRes.isOk) {
            setLoading(false);
            return;
          }
        /*   console.log(walletStocksRes.data) */
           setWallet(walletStocksRes.data.map((item: WalletItem) => item.ticker));
          setLoading(false);
        })
        .catch(error => {
          console.error("Error in Promise.all", error);
        });
    } else {
      setLoading(false);
    }
  }, [user.id, removeUser, setUser]);

  if (isLoading) {
    return (
      <Center h="100vh" w="100vw">
        <Loading />
      </Center>
    );
  }

  switch (type) {
    case "public":
      return <PublicACL>{children}</PublicACL>;

    case "app":
      return <AppACL>{children}</AppACL>;

  }
}