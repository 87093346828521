
import { useState } from "react";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Loading } from "../../../components/Loading";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import { InputText } from "../../../components/InputText";
import ChartStatistics from "../../../components/ChartStatistics";
import { httpRequest } from "../../../services/api";
import { Button } from "../../../components/Button";
import { HistoricDataType } from "../../../types/mainDataType";
import { userStore } from "../../../store/user";
import { useNavigate } from "react-router-dom";

export function StockScoreHistoryScreen() {
    const {user} = userStore();
    const [stockValue, setStockValue] = useState<string | undefined>('');
  const [arrayToShow, setArrayToshow] = useState<{ year: number; ca: number; }[]>([])
const [isLoading, setLoading] = useState(false);
const [backendRes, setBackendRes] = useState<HistoricDataType>({} as HistoricDataType)
    const handleSearch = () => {
      setLoading(true)
      httpRequest.get(`/getStockHistoricalInfo/?ticker=${stockValue}`).then(res => {
        if(res.isOk){
          setBackendRes(res.data)
          setArrayToshow(res.data)
          setLoading(false)
        }else{
          setBackendRes({} as HistoricDataType)
          setArrayToshow([])
          setLoading(false)
        }
      })      
    }
    interface DataItem {
      year: number;
      ca: number | null;
  }
    function calculateMean(data: DataItem[]): number {
      // Filter the first 5 items
      const first5Items = data.slice(0, 5);
  
      // Calculate the arithmetic mean of prop ca, considering 0 if ca is less than or equal to 0
      const caValues = first5Items.map(item => item.ca !== null && item.ca > 0 ? item.ca : 0);
      const sum = caValues.reduce((acc, val) => acc + val, 0);
      const mean = sum / caValues.length || 0; // Prevent division by zero
  
      return mean;
  }

  const navigate = useNavigate();

      if(!user.paid){
        return (
            <Flex flexDir='column'>
            <Text>Área exclusiva para usuários premium.</Text>
            <Text alignSelf={'center'} fontSize='lg' color={'green.50'} fontWeight='bold' _hover={{
              cursor: "pointer",
            }} onClick={() => {
        navigate(`/app/conta`)
        }}>Assine já!</Text>
            </Flex>
        )
      }
    return (
      <>
            <Card p='16px'>
              <CardBody>
                <Flex direction='row' w='100%'>
                  <InputText
                    name="stock"
                    label="Procure uma ação (ticker)"
                    onChange={e => {
                      setStockValue(e.target.value.toLocaleUpperCase());
                    }}
                    value={stockValue}
                    w={220}
                  />
                  <Button text="Pesquisar"  mx="4" mb="4"w='100%' color="green" onClick={() => {
                       handleSearch()
    }}/> 
                </Flex>
                {
    isLoading && <Loading/>
  }
  {
    !isLoading && arrayToShow.length > 0 && 
    <>
   <ChartStatistics
      title={'CA médio (últimos 5 anos)'}
      amount={ calculateMean(arrayToShow).toFixed(2) + '%'}

    />
    <Box h={10} /> 
    <SimpleGrid gap={{ sm: "48px" }} columns={3} >
      
      {
        arrayToShow.map(element => {
          return (
            <>
                   <ChartStatistics
      title={element.year}
      amount={ Number(element.ca) < 0 || Number(element.ca) === Infinity || isNaN(element.ca!) || !element.ca?  'N/A' : element.ca?.toFixed(2) + '%'}

    />
            </>
          )
        })
      }
  </SimpleGrid> 
    </>
  }
  {
    !isLoading && Object.values(backendRes).length === 0 &&  <Text>Selecione uma ação válida</Text>
  }
              </CardBody>
            </Card>
        
      </>
  
    );
  };
