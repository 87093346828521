import Projects from "../../../components/RankingTable";
import { useEffect, useState } from "react";
import { httpRequest } from "../../../services/api";
import { Loading } from "../../../components/Loading";
import { mainDataStore } from "../../../store/mainData";
import { userStore } from "../../../store/user";
import { MainDataType } from "../../../types/mainDataType";
import { arrayToBeFiltered } from "../../../helpers/constants";


export function RankingStockScoreScreen() {

      const [isLoading, setLoading] = useState(false);
      const {mainData, setMainData} = mainDataStore();
      const {user} = userStore();
      

      useEffect(() => {
        if(mainData.length === 0){
          setLoading(true);
            if(Object.keys(user).length > 0){
              if(!user.paid){
                httpRequest.get('/stocksPaginated?page=1&totalPerPage=1000').then(res => {
                  if (res.isOk) {
                    const newArray = res.data.items
                    setMainData( newArray);
                  }
                  setLoading(false);
                });
                return;
              }
              httpRequest.get('/stocksPaginatedPremium').then(res => {
                if (res.isOk) {
                  const newArray = res.data.items
                  setMainData(newArray.sort((a: any, b: any) => {
                    // First, sort by score in descending order
                    if (b.score !== a.score) {
                      return b.score - a.score;
                    }
                  
                    // If scores are the same, use ca as a tiebreaker
                    return b.ca - a.ca;
                  }));
                }
                setLoading(false);
              });
            }
        
        }
  
      }, [user]);
     
    return (

        <>
      {      isLoading ? <Loading />  :    <Projects
          title={"Ações"}
          amount={mainData.length}
          captions={["Nome", "Código", "Último", "Score", "CA", "Carteira"]}
          data={mainData}
          hasRemove={true}
        />}
        </>
    )
 }