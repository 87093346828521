import { PropsWithChildren } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { Remove } from "../../types/shared";

type Props = Remove<FlexProps, "as" | "children">;

export function Form({ children, ...rest }: PropsWithChildren<Props>) {
  return (
    <Flex as="form" direction="column" {...rest}>
      {children}
    </Flex>
  );
}