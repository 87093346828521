import { PropsWithChildren } from "react";
import { PublicLayout } from "./public";
import { AppLayout } from "./app";

import { LayoutTypes } from "../types";


type Props = {
  type: LayoutTypes;
}

export function LayoutWrapper(props: PropsWithChildren<Props>) {
  switch (props.type) {
    case "none":
      return (
        <>
          {props.children}
        </>
      );

    case "public":
      return (
        <PublicLayout>
          {props.children}
        </PublicLayout>
      );

    case "app":
      return (
        <AppLayout>
          {props.children}
        </AppLayout>
      );
    default:
      return <>{props.children}</>
  }
}