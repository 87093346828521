import { useState } from "react";
import { SignUpFormStep1 } from "./SignUpFormStep1";


export function LoginForm() {
  const [currentStep, setCurrentStep] = useState(1);


  return (
    <>
      {currentStep === 1 && (
        <SignUpFormStep1
          setCurrentStep={setCurrentStep}
        />
      )}

    {/*   {currentStep === 2 && (
        <LoginFormStep2 />
      )} */}
    </>
  );
}