import create from "zustand";
import { MainDataType } from "../types/mainDataType";

export interface MainData {
  mainData: MainDataType[];
  setMainData:(mainData: any) => void
}

export const mainDataStore = create<MainData>((set) => ({
    mainData: [] ,

  setMainData: (mainData : any) => set(state => ({
    ...state,
    mainData
  })),
}));