import ReactInputMask from "react-input-mask";
import { forwardRef, ForwardRefRenderFunction, ReactElement } from "react";
import { Input, InputProps, FormControl, FormErrorMessage, InputGroup, InputLeftElement } from "@chakra-ui/react";

interface Props extends InputProps {
  name: string;
  mask: string;
  errorMessage?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, Props> = ({ name, errorMessage, leftIcon, rightIcon, mask, ...rest }, ref) => {
  return (
    <FormControl isInvalid={Boolean(errorMessage)} mb="4">
     
      <InputGroup>
        {leftIcon && (
          <InputLeftElement h="100%">
            {leftIcon}
          </InputLeftElement>
        )}

        <Input
          id={`name_${name}-randomId_${Math.round(Math.random() * 1000)}`}
          as={ReactInputMask}
          mask={mask}
          name={name}
          focusBorderColor="gray.900"
          bgColor="gray.50"
          borderColor="gray.50"
          _hover={{ bgColor: "gray.50" }}
          borderRadius="md"
          variant="filled"
          size="md"
          ref={ref}
          {...rest}
        />

      </InputGroup>

      {Boolean(errorMessage) && (
        <FormErrorMessage mt="0" ml="2">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

export const InputMask = forwardRef(BaseInput);