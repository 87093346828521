import { Flex } from "@chakra-ui/react";
import { LoginForm } from "./partials/LoginForm";

export function Login() {
  return (
      <Flex justify="center" 
      align="center" 
      p="8" 
      bgColor="#F5F5F5" 
      w={476} 
      m="auto" 
      mx={"5"} 
      borderRadius="12" 
      direction="column"> 

      <LoginForm/>
      </Flex>
  );

  }