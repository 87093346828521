import { useToast } from "@chakra-ui/react";
import create from "zustand";
import { httpRequest } from "../services/api";

export interface Wallet {
  wallet: string[];
  addTickToWallet:(tick: string, companyName: string) => void;
  removeTickFromWallet:(tick: string, companyName: string) => void;
  setWallet: (walletArray: string[]) => void;
  walletIsLoading: boolean;
}

export const walletStore = create<Wallet>((set) => ({
  
    wallet: [] ,
    walletIsLoading: false,
    setWallet: walletArray => set(() => ({
      wallet: walletArray,
    })),
    addTickToWallet: async (tick: string, companyName: string) => 
    {
      set(() => ({ walletIsLoading: true })); 
      await httpRequest.post("/wallet-stocks", { ticker: tick, companyName: companyName});
     
      set((state) => ({
          ...state,
          wallet: [...state.wallet, tick],
          walletIsLoading: false
        }))
    },
    
      removeTickFromWallet: async (tick: string, companyName: string) => {
      set(() => ({ walletIsLoading: true })); 
     await httpRequest.post("/wallet-stocks", { ticker: tick, companyName: companyName});
        set((state) => ({
        ...state,
        wallet: state.wallet.filter((t) => t !== tick),
        walletIsLoading: false

      }))},
}));


