// Chakra imports
import {
    Flex,
    Icon,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";

  // Custom components
  import Card from "../Card/Card";
  import CardHeader from "../Card/CardHeader";
  import DashboardTableRow from "../Tables/DashBoardTableRow";
  import React, { useState } from "react";
  import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";


  
import { InputText } from "../../components/InputText";
import { MainDataType } from "../../types/mainDataType";
import { userStore } from "../../store/user";

  
  const Projects = ({ title, amount, captions, data, hasRemove }: any) => {
    const textColor = useColorModeValue("gray.700", "white");
    const [stockValue, setStockValue] = useState('');
    const {user} = userStore();

  
    return (
      <Card p='32px' overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='12px 0px 28px 0px'>
          <Flex direction='column'>
            <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
              {title}
            </Text>
            <Flex align='center' mb={8}>
              <Icon
                as={IoCheckmarkDoneCircleSharp}
                color='green.900'
                w={4}
                h={4}
                pe='3px'
     
              />
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                <Text fontWeight='bold' as='span'>
                  {amount} ações
                </Text>{" "}
                liberadas para você.
              </Text>
            </Flex>
            <InputText
                    name="stock"
                    label="Procure uma ação (ticker)"
                    onChange={e => {
                      setStockValue(e.target.value.toLocaleUpperCase());
                    }}
                    value={stockValue}
                    w={220}
                  />
          </Flex>
        </CardHeader>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' ps='0px'>
              {captions.map((caption: any, idx: any) => {
                return (
                  <>
                 {!user.paid && caption === 'Carteira' ? undefined : <Th color='gray.400' key={idx}>
                    {caption}
                  </Th>}
                  </>
                  
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.filter((e: MainDataType) => e.Ativo.includes(stockValue) ).map((row: MainDataType) => {
              return (
                <DashboardTableRow
                  key={row.Ativo}
                  name={row.Empresa}
                  members={row.Ativo}
                  budget={row.Cotacao}
                  score={row.score}
                  ca={row.ca}
                  pl={row.P_L}
                  pvp={row.P_VP}
                  hasRemove={hasRemove}
                />
              );
            })}
          </Tbody>
        </Table>
      </Card>
    );
  };
  
  export default Projects;
  