import { Box, Flex, Text, useColorModeValue, Image} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { InputText } from "../../../components/InputText";
import { useToast } from "../../../hooks/useToast";
import { httpRequest } from "../../../services/api";
import { mainDataStore } from "../../../store/mainData";
import { userStore } from "../../../store/user";

export function Conta() {
    const navigate = useNavigate();
    const toast = useToast();
    const {setMainData} = mainDataStore();
    const {setUser} = userStore();
    const {removeUser, user} = userStore();
    const textColor = useColorModeValue("gray.700", "white");
    const [showCardInfo, setShowCardInfo] = useState(false)
    const [showCancelSub, setShowCancelSub] = useState(false)

    const [isLoading, setLoading] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
/*     const [expMonth, setExpMonth] = useState('');
    const [expYear, setExpYear] = useState(''); */
    const [expDate, setExpDate] = useState('');
    // const [expDateError, setExpDateError] = useState('');

    const [cvv, setCvv] = useState('');

    const handleBecomePremium = async () => {
        setLoading(true);
        const result = await httpRequest.post("/pagarme",{
            holderName: cardName,
            cardNumber: Number(cardNumber),
            expMonth: Number(expDate.split('/')[0]),
            expYear: Number(expDate.split('/')[1]) + 2000,
            cvv: Number(cvv)
        });
        if (!result.isOk) {
            setLoading(false)
            toast.error("Houve um erro, verifique seus dados " + result.error);
            return;
        }
        if (result.data.status === 'processing') {
            setLoading(false)
            toast.error("Seu pagamento está sendo processado! Por favor, aguarde ou entre em contato.");
            return;
        }
        if (result.data.status !== 'active') {
            setLoading(false)
            toast.error("Houve um erro, verifique seus dados");
            return;
        }
        if (result.data.status === 'active') {
            await httpRequest.get("/auth/me")
            setUser({
                ...user,
                paid: true
            })
            toast.success("Parabéns, você agora é um usuário premium iNB3!");
            setShowCardInfo(false);
            setMainData([])
            navigate('/app')
            return;
        }
        setLoading(false)
    }

    const handleCancelSub = async () => {
        setLoading(true);
        const result = await httpRequest.post("/pagarme/cancelRecurrency",{
            subscriptionId: user.subscriptionId
        });
       
        if (!result.isOk) {
            setLoading(false)
            toast.error("Houve um erro, por favor, entre em contato");
            return;
        }
        if (result.data.status === 'canceled') {
            await httpRequest.get("/auth/me")
            setLoading(false)
            toast.success("Sua assinatura foi cancelada com sucesso");
            setUser({
                ...user,
                paid: false
            })
            setMainData([]);
            navigate('/app')
            return;
        }
        setLoading(false)
    }

    // const validateExpDate = (date:string) => {
    //     const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    //     return regex.test(date);
    // };

    const handleExpDateChange = (e: any) => {
        let date = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
        if (date.length >= 3) {
            date = date.slice(0, 2) + '/' + date.slice(2, 4);
        }
        setExpDate(date);
        // if (!validateExpDate(date)) {
        //     setExpDateError("Formato inválido. Use MM/YY");
        // } else {
        //     setExpDateError("");
        // }
    };




    return (
        <Flex flexDir="column" p={8} minW={320} maxW={580}>
        <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>Nome:</Text> <Text fontSize='lg' color={'green.50'} fontWeight='bold' pb='.5rem'>{user.name}</Text>
        <Box h={2}/>
        <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>E-mail: </Text> <Text fontSize='lg' color={'green.50'} fontWeight='bold' pb='.5rem'>{user.email}</Text>
        <Box h={2}/>
        <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>Premium: {user.paid ?  <Text fontSize='lg' color={'green.900'} fontWeight='bold' pb='.5rem'>ATIVO</Text> : <Text fontSize='lg' color={'red'} fontWeight='bold' pb='.5rem'>INATIVO</Text>}</Text>
        <Box h={2}/>
        {!user.paid && <Text fontSize='lg' color={'green.50'} fontWeight='bold' pb='.5rem'>Nossa assinatura custa R$ 20,00 ao mês (cobrada a cada 30 dias no seu cartão de crédito), e pode ser cancelada a qualquer momento.</Text>}
       {!user.paid && !showCardInfo && <Button color={"green.900"} onClick={() => {
               setShowCardInfo(true)
        }} text="Assinar"/> }
        {
            showCardInfo &&
            <Flex flexDir="column">
                <Box h={4}/>
                   <InputText
                    name="cardName"
                    label="Nome impresso no cartão"
                    onChange={e => {
                        setCardName(e.target.value);
                    }}
                    value={cardName}
                />
                <InputText
                    name="cardNumber"
                    label="Número do cartão"
                    onChange={e => {
                        setCardNumber(e.target.value);
                    }}
                    value={cardNumber}
                    type={"number"}
                />
                 {/* <InputText
                    name="expMonth"
                    label="Mês de vencimento (1 a 12)"
                    onChange={e => {
                        setExpMonth(e.target.value);
                    }}
                    value={expMonth}
                    type={"number"}

                />
                    <InputText
                    name="expYear"
                    label="Ano de vencimento(ex: 2027)"
                    onChange={e => {
                        setExpYear(e.target.value);
                    }}
                    value={expYear}
                    type={"number"}
                /> */}
                <InputText
                    name="espDate"
                    label="Vencimento (MM/AA)"
                    onChange={handleExpDateChange}
                    value={expDate}
                    type={"text"}
                />
                 {/* {expDateError && <Text color="red.500">{expDateError}</Text>} */}

<InputText
                    name="CVV"
                    label="CVV (3 dígitos. Ex: 456)"
                    onChange={e => {
                        setCvv(e.target.value);
                        console.log(e.target.value.length)
                    }}
                    value={cvv}
                    type={"number"}
                    
                />
                 <Image src="/images/pngs/pagarme.svg" alt="inb3 logo" alignSelf='center'/>
                 <Box h={4}/>
                 <Button color={"green.900"} isLoading={isLoading} onClick={() => {
               handleBecomePremium()
        }} text="Assinar"/>
            </Flex>
        }
          {user.paid && !showCancelSub && <Button color={"red"} onClick={() => {
               setShowCancelSub(true)
        }} text="Cancelar assinatura"/>}
        {
            showCancelSub && <Button color={"red"} isLoading={isLoading} onClick={() => {
               handleCancelSub()
         }} text="Deseja mesmo cancelar?"/>
        }
        <Box h={8}/>
        <Button color={"red"} onClick={() => {
                           localStorage.removeItem("inb3.bearerToken");
                           setMainData([])
                           removeUser();
                navigate("/login");
         
        }} text="Sair"/>
          <Box h={8}/>
        </Flex>
    )
 }