import { Card, CardBody, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoArrowDownCircle, IoArrowUpCircle } from "react-icons/io5";
import { Button } from "../../../components/Button";
import ChartStatistics from "../../../components/ChartStatistics";
import { InputText } from "../../../components/InputText";
import { Loading } from "../../../components/Loading";
import { mainDataStore } from "../../../store/mainData";
import { userStore } from "../../../store/user";
import { MainDataType } from "../../../types/mainDataType";

export function ComparingCurrentStockScoreScreen() {
    const [stockValue, setStockValue] = useState<string | undefined>('');
    const {mainData} = mainDataStore();
    const {user} = userStore();
    const [arrayToCompare, setArrayToCompare] = useState<MainDataType[]>([])
    const [filteredStock, setFilteredStock] = useState([{} as MainDataType]);
    useEffect(() => {
      setFilteredStock(mainData.filter(e => e?.Ativo.includes(stockValue!)))
    }, [stockValue])
    return (
      <>{
mainData.length > 0 ?
        <Flex direction='column'>
            <Card p='16px'>
              <CardBody>
                <Flex direction='column' w='100%'>
                    <Flex direction={'row'} justifyContent={'flex-start'} align={'center'}>
                    <InputText
                    name="stock"
                    label="Procure uma ação (ticker)"
                    onChange={e => {
                      setStockValue(e.target.value.toLocaleUpperCase());
                    }}
                    value={stockValue}
                    w={220}
                  />
                  {
                    arrayToCompare.length === 0 ? 
                    <Button text="Fixar para comparação"  mx="4" mb="4"w='100%' color="green" onClick={() => {
                        setArrayToCompare(state => [
                            ...state,
                            filteredStock[0]
                        ])
                       /* setFilteredStock([])  */
                        setStockValue('')
    }}/>  :   <Button text="Remover fixada"  mx="4" mb="4"w='100%' color="red" onClick={() => {
        setArrayToCompare([

        ])
        setFilteredStock([])
        setStockValue('')
}}/> 
                  }
                
                    </Flex>
                 
                  {filteredStock.length === 1 && filteredStock[0]?.Ativo !== '' && <>
                    <Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
                      <Text fontSize='md' fontWeight='medium' color='gray.400'>
                        <Text
                          fontSize={42}
                          as='span'
                          fontWeight='bold'>
                         R$ {filteredStock[0].Cotacao?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false
  })}
                        </Text>
                        {` cotação atual de ${filteredStock[0]?.Ativo}`}
                      </Text>
                    </Flex>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} >
                         <ChartStatistics
                        title={"P/VP"}
                        amount={filteredStock[0].P_VP}
                        amountToCompare={arrayToCompare[0]?.P_VP ? arrayToCompare[0]?.P_VP : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                      />
                      <ChartStatistics
                      
                        title={"Score"}
                        amount={user.paid ? filteredStock[0].score : '🔒'}
                        
                      />
                      <ChartStatistics
                        title={"CA"}
                        amount={user.paid ? Number(filteredStock[0].ca) < 0 || Number(filteredStock[0].ca) === Infinity || isNaN(filteredStock[0].ca!) || filteredStock[0].P_VP < 0 ||  filteredStock[0].P_L < 0 ? 'N/A' : filteredStock[0].ca?.toFixed(2) + '%' : '🔒'}
                        
                      />
                    </SimpleGrid>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                      <ChartStatistics
                        title={"P/L"}
                        amount={filteredStock[0].P_L ? filteredStock[0].P_L  : 0}
                        amountToCompare={arrayToCompare[0]?.P_L}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
       
                      />
                      <ChartStatistics
                        title={"Dividend Yield"}
                        amount={filteredStock[0].Dividend_Yield? filteredStock[0].Dividend_Yield + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={arrayToCompare[0]?.Dividend_Yield ? arrayToCompare[0]?.Dividend_Yield : 0}
                      />
                      <ChartStatistics
                        title={"Dívida Líquida/EBIT"}
                        amount={filteredStock[0].Divida_Liquida_EBIT ? filteredStock[0].Divida_Liquida_EBIT : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={arrayToCompare[0]?.Divida_Liquida_EBIT ? arrayToCompare[0]?.Divida_Liquida_EBIT : 0}


                      />
                    </SimpleGrid>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                      <ChartStatistics
                        title={"Margem EBIT"}
                        amount={filteredStock[0].Margem_Ebit ? filteredStock[0].Margem_Ebit + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={arrayToCompare[0]?.Margem_Ebit ? arrayToCompare[0]?.Margem_Ebit : 0}


                      />
                      <ChartStatistics
                        title={"ROE"}
                        amount={filteredStock[0]["ROE"] ? filteredStock[0]["ROE"] + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={arrayToCompare[0] ? arrayToCompare[0]["ROE"] : 0}
         
                      />
                      <ChartStatistics
                        title={"ROIC"}
                        amount={filteredStock[0]["ROIC"] ? filteredStock[0]["ROIC"] + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={arrayToCompare[0] ? arrayToCompare[0]["ROIC"] : 0}

            
                      />
                    </SimpleGrid>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                   
                      <ChartStatistics
                        title={"Setor"}
                        amount={filteredStock[0]["Setor"]}
  
                      />
                      <ChartStatistics
                        title={"Segmento"}
                        amount={filteredStock[0]["Segmento"]}
  
                      />
                   <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"Nuvem de Ichimoku"}
                      amount={filteredStock[0]["nuvemIchimoku"]?.toFixed(2) ? filteredStock[0]["nuvemIchimoku"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["nuvemIchimoku"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    </SimpleGrid>
  
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                          <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 50"}
                      amount={filteredStock[0]["ema50"]?.toFixed(2) ? filteredStock[0]["ema50"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["ema50"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                           <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 200"}
                      amount={filteredStock[0]["ema200"]?.toFixed(2) ? filteredStock[0]["ema200"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["ema200"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                               <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 20"}
                      amount={filteredStock[0]["ema20"]?.toFixed(2) ? filteredStock[0]["ema20"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["ema20"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    </SimpleGrid>
                  </>}
  
                </Flex>
              </CardBody>
          </Card>
          {
            arrayToCompare.map(e => {
                return(
                    <>
                    <Card p='16px'>
              <CardBody>
                <Flex direction='column' w='100%'>
                  {arrayToCompare.length === 1 && e?.Ativo !== '' && <>
                    <Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
                      <Text fontSize='md' fontWeight='medium' color='gray.400'>
                        <Text
                          fontSize={42}
                          as='span'
                          fontWeight='bold'>
                         R$ {e.Cotacao.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false
  })}
                        </Text>
                        {` cotação atual de ${e?.Ativo}`}
                      </Text>
                    </Flex>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} >
                  
                         <ChartStatistics
                        title={"P/VP"}
                        amount={e.P_VP}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0]?.P_VP}

                      />
                      <ChartStatistics
                      
                        title={"Score"}
                        amount={user.paid ? e.score : '🔒'}
                        
                      />
                      <ChartStatistics
                        title={"CA"}
                        amount={user.paid ? Number(e.ca) < 0 || Number(e.ca) === Infinity || isNaN(e.ca!) || e.P_VP < 0 ||  e.P_L < 0 ? 'N/A' : e.ca?.toFixed(2) + '%' : '🔒'}
                        
                      />
                    </SimpleGrid>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                      <ChartStatistics
                        title={"P/L"}
                        amount={e.P_L ? e.P_L : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0]?.P_L ? filteredStock[0]?.P_L : 0}

                      />
                      <ChartStatistics
                        title={"Dividend Yield"}
                        amount={e.Dividend_Yield ? e.Dividend_Yield + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0]?.Dividend_Yield ? filteredStock[0]?.Dividend_Yield : 0}
                      />
                      <ChartStatistics
                        title={"Dívida Líquida/EBIT"}
                        amount={e.Divida_Liquida_EBIT ? e.Divida_Liquida_EBIT < -0.9 ? -0.9 : e.Divida_Liquida_EBIT : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0]?.Divida_Liquida_EBIT ? filteredStock[0]?.Divida_Liquida_EBIT : 0}

                      />
                    </SimpleGrid>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                      <ChartStatistics
                        title={"Margem EBIT"}
                        amount={e.Margem_Ebit ? e.Margem_Ebit + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0]?.Margem_Ebit? filteredStock[0]?.Margem_Ebit : 0}


                      />
                      <ChartStatistics
                        title={"ROE"}
                        amount={e["ROE"] ? e["ROE"] + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0] && filteredStock[0]["ROE"]? filteredStock[0]["ROE"] : 0}


                      />
                      <ChartStatistics
                        title={"ROIC"}
                        amount={e["ROIC"] ? e["ROIC"] + '%' : 0}
                        isComparing={arrayToCompare.length === 1 && filteredStock.length === 1}
                        amountToCompare={filteredStock[0] && filteredStock[0]["ROIC"]? filteredStock[0]["ROIC"]: 0}


                      />
                    </SimpleGrid>
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                   
                      <ChartStatistics
                        title={"Setor"}
                        amount={e["Setor"]}
  
                      />
                      <ChartStatistics
                        title={"Segmento"}
                        amount={e["Segmento"]}
  
                      />
                  <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"Nuvem de Ichimoku"}
                      amount={e["nuvemIchimoku"]?.toFixed(2) ? e["nuvemIchimoku"]?.toFixed(2) : 0}
                    />
                    {e["nuvemIchimoku"] <= e.Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    </SimpleGrid>
  
                    <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                    <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 50"}
                      amount={e["ema50"]?.toFixed(2) ? e["ema50"]?.toFixed(2) : 0}
                    />
                    {e["ema50"] <= e.Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 200"}
                      amount={e["ema200"]?.toFixed(2) ? e["ema200"]?.toFixed(2) : 0}
                    />
                    {e["ema200"] <= e.Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 20"}
                      amount={e["ema20"]?.toFixed(2) ? e["ema20"]?.toFixed(2) : 0}
                    />
                    {e["ema20"] <= e.Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    </SimpleGrid>
                  </>}
  
                </Flex>
              </CardBody>
          </Card>
                    </>
                )
            })
          }
         
        </Flex>    : <Loading/>
      }
      </>
    );
  };