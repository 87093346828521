import { Form } from "../../../components/Form";
import * as yup from "yup";
import { FormEvent, useEffect, useState } from "react";
import { Flex, Text, Image, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { InputText } from "../../../components/InputText";
import { Button } from "../../../components/Button";
import { httpRequest } from "../../../services/api";
import { useToast } from "../../../hooks/useToast";

export function ForgotPassword() {
  const [isLoading, setLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const toast = useToast();
  const [showNextStep, setShowNextStep] = useState(false)

  const [code, setCode] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('')

  const navigate = useNavigate();
  async function handleLogin(event: FormEvent) {
    event.preventDefault();
    setEmailErrorMessage("");
    if (!emailValue) {
      return setEmailErrorMessage("E-mail é obrigatório");
    }
    yup
      .string()
      .email()
      .required()
      .validate(emailValue)
      .then((emailValidated) => {
        setEmailValue(emailValidated);
      })
      .catch(() => {
        return setEmailErrorMessage("E-mail inválido");
      });
    setLoading(true);
    const result = await httpRequest.post<string>("/users/recover-password", { email: emailValue });
        if (!result.isOk) {
            setLoading(false);
             toast.error('Houve um erro! Por favor, corrija os dados!');
            return;
        }
        setShowNextStep(true)

    setLoading(false);
  }

  async function handleChangePassword (){
    if(!code || !password1 || !password2){
      toast.error('Por favor, preencha todos os campos!')
      return
    }
    if(password1 !==password2){
      toast.error('Por favor, as senhas precisam ser iguais!')
      return
    }
    setLoading(true);
    const result = await httpRequest.post<string>("/users/change-password", { email: emailValue, password: password1, verificationPassword: password2, code: code });
        if (!result.isOk) {
            setLoading(false);
             toast.error('Houve um erro! Por favor, corrija os dados!');
            return;
        }
        toast.success('Senha alterada com sucesso!');
        navigate("/login")
    setLoading(false);

  }
  return (
    <Flex
      justify="center"
      align="center"
      p="8"
      bgColor="#F5F5F5"
      w={476}
      m="auto"
      mx={"5"}
      borderRadius="12"
      direction="column"
    >
      <>
      {
        showNextStep ? <Flex  width='100%'justifyContent='center' alignItems='center'  flexDir='column' mt="1.5" >
           <Flex alignSelf="center"><Image src="/images/pngs/inb3_logo.png" alt="inb3 logo" w={130} /></Flex>
          <Text color="blue.900" fontSize="2xl" mb="1">
            Recuperar senha
          </Text>
          <Text color="blue.50" fontSize="sm" >
            Por favor, insira sua nova senha 
          </Text>
          <Text color="blue.50" fontSize="sm" mb="8">
            e o código enviado para o e-mail.
          </Text>

          <InputText
            name="code"
            label="Código"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            value={code}


          />

<InputText
            name="password1"
            type="password"
            label="Nova senha"
            onChange={(e) => {
              setPassword1(e.target.value);
            }}
            value={password1}

          />

<InputText
            name="password2"
            type="password"
            label="Repita a nova senha"
            onChange={(e) => {
              setPassword2(e.target.value);
            }}
            value={password2}

          />

          <Button text="Trocar senha" my="1" loading={isLoading} w='100%' onClick={handleChangePassword}/>

          <Flex
            alignSelf="flex-start"
            mt="12"
            direction="column"
            _hover={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            <Flex direction="row" align="center">
              <AiOutlineArrowLeft color="#314BCE" size={20} />
              <Text color="blue.50" ml="2" mt="0.5" fontWeight="semibold">
                Voltar para o login
              </Text>
            </Flex>
          </Flex>
        </Flex> : 
        <Form onSubmit={handleLogin} w="100%" justify="center" mt="1.5">
          
            <Flex alignSelf="center"><Image src="/images/pngs/inb3_logo.png" alt="inb3 logo" w={130} /></Flex>
          <Text color="blue.900" fontSize="2xl" mb="1">
            Recuperar senha
          </Text>
          <Text color="blue.50" fontSize="sm" mb="8">
            Por favor, coloque seu e-mail para resetar sua senha.
          </Text>

          <InputText
            name="emailOrPhone"
            label="Endereço de e-mail"
            onChange={(e) => {
              setEmailValue(e.target.value);
              setEmailErrorMessage("");
            }}
            value={emailValue}
            errorMessage={emailErrorMessage}
          />

          <Button text="Enviar" type="submit" my="1" loading={isLoading} />

          <Flex
            alignSelf="flex-start"
            mt="12"
            direction="column"
            _hover={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            <Flex direction="row" align="center">
              <AiOutlineArrowLeft color="#314BCE" size={20} />
              <Text color="blue.50" ml="2" mt="0.5" fontWeight="semibold">
                Voltar para o login
              </Text>
            </Flex>
          </Flex>
        </Form>
      }
        
      </>
    </Flex>
  );
}
