import * as yup from "yup";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Form } from "../../../../components/Form";
import { InputText } from "../../../../components/InputText";
import { Button } from "../../../../components/Button";
import { httpRequest } from "../../../../services/api";
import { useToast } from "../../../../hooks/useToast";
import { useForm } from "react-hook-form";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputMask } from "../../../../components/InputMasked";
import { userStore } from "../../../../store/user";

interface Props {
    setCurrentStep: Dispatch<SetStateAction<number>>;
}

interface FormDataProps {
    name: string;
    phone: string;
    cpf: string;
    email: string;
    password: string;
    passwordConfirmation: string;
}


function schemaResolverStep1() {
    const schema: yup.SchemaOf<FormDataProps> = yup.object().shape({
        email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
        password: yup
            .string()
            .required("Senha é obrigatória")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                "Mínimo de 8 caracteres. Deve conter pelo menos 1 maiúsculo, 1 minúsculo e 1 numeral."
            ),
        passwordConfirmation: yup.string().required("Confirmação de senha é obrigatória").oneOf([yup.ref("password")], "Senhas não conferem"),
        name: yup.string().required("Nome é obrigatório"),
        cpf: yup.string().required("CPF é obrigatório"),
        phone: yup.string().required("Telefone é obrigatório"),
    });

    return yupResolver(schema);
}


export function SignUpFormStep1({ setCurrentStep }: Props) {
    const navigate = useNavigate();
    const {setUser} = userStore();

    const [isLoading, setLoading] = useState(false);

    const { handleSubmit, formState, register } = useForm<FormDataProps>({
        resolver: schemaResolverStep1()
    });
    const toast = useToast();

    const onSubmit = handleSubmit(async ({ name, phone, cpf, email, password, passwordConfirmation}) => {
        setLoading(true)
        const result = await httpRequest.post("/users",{
            name: name,
            email: email,
            password: password,
            phone: phone,
            cpf: cpf,
            verificationPassword: passwordConfirmation
        });

        if (!result.isOk && result.error) {
        setLoading(false)
            toast.error(result.error);
            return;
        }

        const result2 = await httpRequest.post("/auth/login", { email: email, password });

        if (!result2.isOk) {
            setLoading(false);
            if (result2.error) toast.error(result2.error);
            return;
        }
        
        const token = result2.data.access_token
        localStorage.setItem('inb3.bearerToken', token);
        httpRequest.get("/auth/me").then(res => {
                  if (!res.isOk) {
                      localStorage.removeItem("inb3.bearerToken");
                      setLoading(false);
                      return;
                    } 
         
                    setUser(res.data);
           
                  });
        navigate("/app");
        setLoading(false)


/*         if (!result.isOk) {
            toast.error(result.error);
            return;
        }


        if (!result.data.sent) {
            toast.error("Houve algum erro ao enviar seu e-mail de verificação");
            return;
        } */
    });


    return (
        <>
            <Form onSubmit={onSubmit} w="100%" justify="center" mt="1.5">
                <Text color="blue.900" fontSize="2xl" mb="4" alignSelf="center">Crie sua conta iNB3</Text>

                <InputText
                    {...register("name")}
                    errorMessage={formState.errors.name?.message}
                    label="Nome"
                />

                <InputMask
                    {...register("phone")}
                    errorMessage={formState.errors.phone?.message}
                    placeholder="Telefone"
                    mask="(99)99999-9999"
                />

                <InputMask
                    {...register("cpf")}
                    errorMessage={formState.errors.cpf?.message}
                    placeholder="CPF"
                    mask="999.999.999-99"
                />

                <InputText
                    {...register("email")}
                    errorMessage={formState.errors.email?.message}
                    label="E-mail"
                    
                />

                <InputText
                    {...register("password")}
                    errorMessage={formState.errors.password?.message}
                    label="Senha"
                    type="password"
                />

                <InputText
                    {...register("passwordConfirmation")}
                    errorMessage={formState.errors.passwordConfirmation?.message}
                    label="Confirmação de senha"
                    type="password"
                    
                />



                <Button text="Cadastrar" type="submit" my="1" loading={isLoading} />

                <Flex alignSelf="flex-start" mt="12" direction="column"
                    _hover={{
                        cursor: "pointer"
                    }}
                    onClick={() => navigate("/login")}
                >
                    <Flex direction="row" align="center">
                        <AiOutlineArrowLeft color="#314BCE" size={20} />
                        <Text color="blue.50" ml="2" mt="0.5" fontWeight="semibold">Voltar para o login</Text>
                    </Flex>
                </Flex>

            </Form>
        </>

    );
}
