import { PropsWithChildren } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
} from '@chakra-ui/react';
import {
  FiSettings,
  FiMenu,
  FiList,
  FiActivity,
  FiBarChart,
  FiCalendar,
  FiCopy,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { mainDataStore } from "../../../store/mainData";

interface LinkItemProps {
  name: string;
  icon: IconType;
  url: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Ranking de ações', icon: FiList, url: "/app/ranking-acao-score"},
  { name: 'Compare ações', icon: FiCopy,   url: "/app/compara-atual-acao-score" },
  { name: 'CA Histórico de ações', icon: FiActivity,   url: "/app/acao-score-historico"},
  { name: 'Ranking da carteira', icon: FiMenu , url: "/app/ranking-carteira-score"},
  { name: 'Score da carteira', icon: FiCalendar,   url: "/app/carteira-score-atual" },
  /* { name: 'Score atual de ações', icon: FiClock,   url: "/app/acao-score-atual" },  need a ticker*/
  { name: 'Entenda a iNB3', icon: FiBarChart,   url: "/app/entenda" },
  { name: 'Conta e pagamento', icon: FiSettings ,   url: "/app/conta"},
 /*  { name: 'Edite sua carteira', icon: FiSettings, FiShuffle  url: "/app/usuario-editar-carteira" }, */
];

export function AppLayout({ children }: PropsWithChildren<{}>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('white.100', 'white.900')} width='100%'>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Flex ml={{ base: 0, md: 72 }} justifyContent="flex-start" pt={20}>
        {children}
      </Flex>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const {setMainData} = mainDataStore();
  const navigate = useNavigate();
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      bgColor="#F5F5F5"
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      alignItems="center"
      overflow={"scroll"}
      {...rest}>
        <Flex justifyContent="flex-end" mt="10" mr='120'>

        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} color={"blue.900"} alignSelf  ="flex-end" mr="4"/>
        </Flex>
      <Flex alignItems="center" ml="20" justifyContent="flex-start " mb={9}>
        <Image src="/images/pngs/inb3_logo.png" alt="inb3 logo" width="90px"/>
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} url={link.url} onClick={onClose}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  url: string
}
const NavItem = ({ url, icon, children, ...rest }: NavItemProps) => {
  return (
    <Link to={url} style={{ textDecoration: 'none', color: "#151940" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "blue.50",
          color: 'green.50',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'green.50',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const navigate = useNavigate();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      justifyContent={{ base: 'space-between'/* , md: 'flex-end' */ }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu color="#151940"/>}
      />
       <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
         <Flex justifySelf={"flex-start"}>
      </Flex>
      </Text>
           
    </Flex>
  );
};


