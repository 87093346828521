import { Spinner, Center, CenterProps, SpinnerProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";


interface Props extends CenterProps, Pick<SpinnerProps, 'size'> {
}

export function Loading({ size, ...rest }: Props) {
  return (
    <Center display="flex" flexDir="column" w="full" h="full" {...rest}>
      <Spinner
        emptyColor="green.900"
        color="blue.50"
        speed="0.65s"
        thickness="3px"
        size={size || "xl"}
      />
      {size !== 'sm' && <>
      <Text>Aguarde um instante.</Text><Text>Estamos garantindo a fidelidade dos dados.</Text>
      </>}
    </Center>
  );
}