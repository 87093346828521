import { useState } from "react";
import { LoginFormStep1 } from "./LoginFormStep1";
/* import { LoginFormStep2 } from "./LoginFormStep2"; */

export function LoginForm() {
  const [currentStep, setCurrentStep] = useState(1);


  return (
    <>
      {currentStep === 1 && (
        <LoginFormStep1
          setCurrentStep={setCurrentStep}
        />
      )}

    {/*   {currentStep === 2 && (
        <LoginFormStep2 />
      )} */}
    </>
  );
}