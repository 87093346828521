import { Box, Flex, Text } from "@chakra-ui/react";

export function Entenda() {
    return (
        <Box padding={8}>
        <Text> Métricas exclusivas</Text>
        <Box h={4}/>
        <Text>
            O CA ou Coeficiente de Atratividade mede o quão interessante se encontra a ação naquele momento. CA &lt; 1% regular, 1% &lt; CA &lt; 3%  bom e CA &gt; 3% acima da média. Ações com CA indeterminado(NA) serão consideradas não atrativas.
        </Text>
        <Box h={4}/>

        <Text>
            O Score (pontuação) da ação é determinado através da combinação da análise técnica e fundamentalista e seus indicadores pré selecionados. O Score é auto explicativo, o mesmo acima de 7 caracteriza uma ação de qualidade excelente, entre 4 e 7 uma ação boa e abaixo de 4 regular.
        </Text>
        <Box h={4}/>
        <Text>
        Empresas com CA’s acima de 200%  podem representar empresas que não estão mais listadas ou que se encontram em recuperação judicial. Para mais detalhes acesse o site da empresa em questão. 
        </Text>

        <Box h={4}/>
        <Text>
        As informações contidas no site levam em consideração a visão de longo prazo como forma de amenizar a volatilidade do mercado no curto prazo. Os dados gráficos são referentes ao período semanal.
        </Text>
       
        <Box h={8}/>
        <Text> Métricas gerais</Text>
        <Box h={4}/>
        <Text>DY: Relaciona os dividendos pagos de acordo com a atual cotação.</Text>
        <Box h={4}/>
        <Text>P/VP: Preço sobre o valor patrimonial, representa o valor intrínseco da companhia. Quanto menor, "mais desvalorizada" se encontra a empresa.</Text>
        <Box h={4}/>
        <Text>P/L : Indica quanto o mercado está disposto a pagar pelos lucros da empresa.</Text>
        <Box h={4}/>
        <Text>Div Liq/EBIT: Mostra quanto tempo seria preciso pagar as dividas da empresa considerando o EBIT.</Text>
        <Box h={4}/>
        <Text>M. EBIT : Utilizada para avaliar a eficiência produtiva da empresa, está relacionada a lucratividade operacional.</Text>
        <Box h={4}/>
        <Text>ROE: Retorno sobre o patrimônio líquido. Indica a capacidade da empresa de gerar valor ao negócio e aos investidores com base nos recursos próprios.</Text>
        <Box h={4}/>
        <Text>ROIC: Representa o retorno sobre o patrimônio investido.</Text>
        <Box h={4}/>
        <Text>MÉDIAS EXPONENCIAIS: Configura-se tendência de alta os ativos que estão com o preço acima de suas médias, e tendência de baixa os ativos que tem seu preço abaixo das médias. As Médias assim como a Nuvem de Ichimoku são referências de tendência de alta, tendência deixa baixa, suportes e resistências. A seta verde pra cima indica que o preço da ação está acima das médias, ou seja, tendência de alta. Já a seta vermelha para baixo, indica que o preço está abaixo das médias, portanto tendência de baixa.</Text>
        <Box h={8}/>
        <Text color={'gray.500'} fontSize={'sm'}>
        Todas as informações apresentadas tem caráter informativo e são provenientes de fontes públicas como B3, CVM, TESOURO NACIONAL, etc. e de dados calculados a partir das informações coletadas. A iNB3 não tem o objetivo de fazer sugestão de compra ou venda de ativos, sendo assim, não se responsabiliza pelas decisões e caminhos tomados a partir da análise das informações aqui apresentadas.
        </Text>
        </Box>
    )
}