import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";

import React, { useEffect, useState } from "react";
import ChartStatistics from "../ChartStatistics";
import { InputText } from "../InputText";
import { httpRequest } from "../../services/api";
import { Loading } from "../Loading";
import { useParams } from "react-router-dom";
import { mainDataStore } from "../../store/mainData";
import { MainDataType } from "../../types/mainDataType";
import { Button } from "../Button";
import { walletStore } from "../../store/wallet";
import { userStore } from "../../store/user";
import { arrayToBeFiltered } from "../../helpers/constants";
import { IoArrowDownCircle, IoArrowUpCircle } from "react-icons/io5";


const StockCurrentScore = ({ title, percentage, chart }: any) => {
  const  {ticker}  = useParams();
  const {user} = userStore();
  const textColor = useColorModeValue("gray.700", "white");
  const [stockValue, setStockValue] = useState<string | undefined>('');
  const {mainData, setMainData} = mainDataStore();
  const {wallet, addTickToWallet, removeTickFromWallet, walletIsLoading} = walletStore();

  const [isLoading, setLoading] = useState(false);


  const [filteredStock, setFilteredStock] = useState<MainDataType[]>([{} as MainDataType]);

  useEffect(() => {
    setStockValue(ticker)
    if(mainDataStore.length === 0){
      setLoading(true);
      if(!user.paid){
        httpRequest.get('/stocksPaginated?page=1&totalPerPage=1000').then(res => {
          if (res.isOk) {
            const newArray = res.data.items.filter((item: MainDataType) => !arrayToBeFiltered.includes(item.Ativo));;
            setMainData(newArray.sort((a: any, b: any) => {
                    // First, sort by score in descending order
                    if (b.score !== a.score) {
                      return b.score - a.score;
                    }
                  
                    // If scores are the same, use ca as a tiebreaker
                    return b.ca - a.ca;
                  }));
          }
          setLoading(false);
        });
      
        return;
      }
      httpRequest.get('/stocksPaginatedPremium').then(res => {
        if (res.isOk) {
          const newArray = res.data.items.filter((item: MainDataType) => !arrayToBeFiltered.includes(item.Ativo));;
          setMainData(newArray.sort((a: any, b: any) => {
            // First, sort by score in descending order
            if (b.score !== a.score) {
              return b.score - a.score;
            }
          
            // If scores are the same, use ca as a tiebreaker
            return b.ca - a.ca;
          }));

        }
        setLoading(false);
      });

    }

  }, []);

  useEffect(() => {
    setFilteredStock(mainData.filter(e => e.Ativo.includes(stockValue!)))
  }, [stockValue, ticker])         

  return (
    <>
      {
        isLoading ? <Loading /> :

          <Card p='16px'>
            <CardBody>
              <Flex direction='column' w='100%'>
                <InputText
                  name="stock"
                  label="Procure uma ação (ticker)"
                  onChange={e => {
                    setStockValue(e.target.value.toLocaleUpperCase());
                  }}
                  value={stockValue}
                  w={220}
                />
                {filteredStock.length === 1 && filteredStock[0].Ativo !== '' && <>
                  <Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                      {title}
                    </Text>
                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                      <Text
                        fontSize={42}
                        as='span'
                        color={percentage !== 0 ? "green" : "red.400"}
                        fontWeight='bold'>
                       R$ {filteredStock[0].Cotacao?.toLocaleString('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false
})}
                      </Text>
                      {` cotação atual de ${filteredStock[0].Ativo}`}
                    </Text>
                  </Flex>
                  <SimpleGrid gap={{ sm: "48px" }} columns={3} >
           
                       <ChartStatistics
                      title={"P/VP"}
                      amount={filteredStock[0].P_VP}
                    />
{                    <ChartStatistics
                    
                      title={"Score"}
                      amount={ /* filteredStock[0].score  */ user.paid ? filteredStock[0].score : '🔒'}
                      
                    />}
                   { <ChartStatistics
                      title={"CA"}
                      amount={/* Number(filteredStock[0].ca) < 0 || Number(filteredStock[0].ca) === Infinity || isNaN(filteredStock[0].ca!) || filteredStock[0].P_VP < 0 ||  filteredStock[0].P_L < 0 ? 'N/A' : filteredStock[0].ca?.toFixed(2) + '%' */ user.paid ? Number(filteredStock[0].ca) < 0 || Number(filteredStock[0].ca) === Infinity || isNaN(filteredStock[0].ca!) || filteredStock[0].P_VP < 0 ||  filteredStock[0].P_L < 0 ? 'N/A' : filteredStock[0].ca?.toFixed(2) + '%' : '🔒'}
                      
                    />}
                  </SimpleGrid>
                  <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                    <ChartStatistics
                      title={"P/L"}
                      amount={filteredStock[0].P_L ? filteredStock[0].P_L : 0}
                    />
                    <ChartStatistics
                      title={"Dividend Yield"}
                      amount={filteredStock[0].Dividend_Yield ? filteredStock[0].Dividend_Yield + '%' : 0}
                    />
                    <ChartStatistics
                      title={"Dívida Líquida/EBIT"}
                      amount={filteredStock[0].Divida_Liquida_EBIT ? /* filteredStock[0].Divida_Liquida_EBIT < -0.9 ? -0.9 :  */filteredStock[0].Divida_Liquida_EBIT : 0}
                    />
                  </SimpleGrid>
                  <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                    <ChartStatistics
                      title={"Margem EBIT"}
                      amount={filteredStock[0].Margem_Ebit ? filteredStock[0].Margem_Ebit + '%' : 0}
                    />
                    <ChartStatistics
                      title={"ROE"}
                      amount={filteredStock[0]["ROE"] ? filteredStock[0]["ROE"] + '%' : 0}
                    />
                    <ChartStatistics
                      title={"ROIC"}
                      amount={filteredStock[0]["ROIC"] ? filteredStock[0]["ROIC"] + '%' : 0}
                    />
                  </SimpleGrid>
                  <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                 
                    <ChartStatistics
                      title={"Setor"}
                      amount={filteredStock[0]["Setor"]}

                    />
                    <ChartStatistics
                      title={"Segmento"}
                      amount={filteredStock[0]["Segmento"]}

                    />
                    <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"Nuvem de Ichimoku"}
                      amount={filteredStock[0]["nuvemIchimoku"]?.toFixed(2) ? filteredStock[0]["nuvemIchimoku"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["nuvemIchimoku"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                  
                  </SimpleGrid>

                  <SimpleGrid gap={{ sm: "48px" }} columns={3} mt={12}>
                
                      <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 50"}
                      amount={filteredStock[0]["ema50"]?.toFixed(2) ? filteredStock[0]["ema50"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["ema50"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 200"}
                      amount={filteredStock[0]["ema200"]?.toFixed(2) ? filteredStock[0]["ema200"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["ema200"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
                    <Flex dir="row" alignItems={"flex-end"}>
                    <ChartStatistics
                      title={"MME 20"}
                      amount={filteredStock[0]["ema20"]?.toFixed(2) ? filteredStock[0]["ema20"]?.toFixed(2) : 0}
                    />
                    {filteredStock[0]["ema20"] <= filteredStock[0].Cotacao ? <IoArrowUpCircle style={{marginBottom: 8}} size={30} color={'green'}/>  :  <IoArrowDownCircle style={{marginBottom: 8}} size={30} color={'red'}/>  }
                    </Flex>
             
                  </SimpleGrid>
                  <>
                  {user.paid && 
                  <>
                  { wallet.includes(filteredStock[0].Ativo) ? <Button isDisabled={walletIsLoading} text="Remover da carteira"  mx="4" my="4" w='100%' color="red" onClick={() => {
                    removeTickFromWallet(filteredStock[0].Ativo, filteredStock[0].Empresa)
}}/>  : <Button isDisabled={walletIsLoading} text="Adicionar a carteira"  mx="4" my="4" w='100%' color="green" onClick={() => {
  addTickToWallet(filteredStock[0].Ativo, filteredStock[0].Empresa)
}}/> }
                  </>}
                  </>
                   
                </>}

              </Flex>
            </CardBody>
          </Card>
      }
    </>

  );
};

export default StockCurrentScore;
