import { Flex, Progress, Text, useColorModeValue } from "@chakra-ui/react";


const ChartStatistics = ({ title, amount, percentage, isComparing, amountToCompare } : any) => {
  const textColor = useColorModeValue("gray.700", "white");
  const getColorScore = (score: number) => {
    if(score > 7) return 'green'
    if(score <= 7 && score >= 4) return 'orange'
    if(score < 4) return 'red'
  }
  const getColorCA = (ca: number) => {
    if(ca > 3) return 'green'
    if(ca <= 3 && ca >= 1) return 'orange'
    if(ca < 1) return 'red'
    return 'red'
  }
  const getComparingColor = () => {
    if(title === 'P/VP' || title === 'P/L' || title === 'Dívida Líquida/EBIT'){
      if(Number(amount?.toString().replace('%', '')) < Number(amountToCompare?.toString().replace('%', ''))) return 'green'
      return 'red'
    }
    if(Number(amount?.toString().replace('%', '')) > Number(amountToCompare?.toString().replace('%', ''))) return 'green'
    return "red"
  }
  return (
    <Flex direction='column' ml={4} w={'100%'}>
      <Flex alignItems='center'>
        <Text fontSize='sm' color='gray.400' fontWeight='semibold'>
          {title}
        </Text>
      </Flex>
      {
        isComparing ? 
        <Text fontSize='lg' color={getComparingColor()} fontWeight='bold' mb='6px' my='6px'>
        {amount}
      </Text>:
        <Text fontSize='lg' color={title === 'Score' || title === 'Score médio' ? getColorScore(amount) : (title === 'CA' || title === 'CA médio' || title === 'CA médio (últimos 5 anos)' ||!isNaN(title) ) && amount ? getColorCA(Number(amount.slice(0, -1))) : textColor} fontWeight='bold' mb='6px' my='6px'>
        {amount}
      </Text>
      }
  
      <Progress
        colorScheme='green'
        borderRadius='12px'
        h='5px'
        value={percentage}
      />
    </Flex>
  );
};

export default ChartStatistics;
